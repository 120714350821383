import {
    USER_DETAIL_SUCCESS,
    USER_DETAIL_FAIL,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    // USER_CREATE_SUCCESS,
    // USER_CREATE_FAIL,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    // USER_UPDATE_SUCCESS,
    // USER_UPDATE_FAIL
} from '../constants/usertypes';

const initialState = {
    users: [],
    user: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case USER_DETAIL_SUCCESS:
            return {
                ...state,
                user: payload,
                success: true
            }
        case USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                users: payload,
                success: false
            }
        case USER_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case USER_DETAIL_FAIL:
            return {
                ...state,
                user: {}
            }
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                success: true
            }
        case USER_DELETE_FAIL:
                return {
                    ...state
                }
        
        default: 
            return state
    }
}