import React from 'react'

export default function Sidebar() {
  return (
    <section className="border-4 bg-white absolute transition-all duration-500 -right-72 group-hover:right-0 z-20">
      <p className="h-screen grid place-items-center w-64">
        <div className="text-4xl">
          There once was a man who walked a golden mile, and all along the way he had a golden smile. He gazed upon the way, never once looking down, to see his feet in scat, which should surely cause a frown!
        </div>
      </p>
    </section>
  );
}
