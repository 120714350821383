import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';

const ResetPasswordConfirm = ({ reset_password_confirm }) => {

  // const [search, setSearch ] = useSearchParams();
   const params = useParams();

  return (
    <figure className="h-screen flex bg-gray-100">
      <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
        <blockquote className="text-2xl font-medium text-center">
          <p className="text-lg font-semibold">Confirm Your Password</p>
        </blockquote>

        <div className="text-primary m-6">
          <div className="flex items-center mt-3 justify-center">
            <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
              Please enter your new password
            </h1>
          </div>
          <Formik
            initialValues={{ new_password: "", re_new_password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.new_password) {
                errors.new_password = "Required";
              } else if (values.new_password.length < 8) {
                errors.new_password =
                  "Your password must be at least 8 characters in length";
              }
              if (values.re_new_password !== values.new_password) {
                errors.new_password = "Your passwords must match";
              }
              if (!values.re_new_password) {
                errors.new_password = "Please repeat your new password";
              }
              return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
        
              setTimeout(() => {
                reset_password_confirm(params.uid, params.token, values.new_password, values.re_new_password);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <input
                  type="password"
                  name="new_password"
                  placeholder="New Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password}
                  className={
                    "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
                  }
                />
                <input
                  type="password"
                  name="re_new_password"
                  placeholder="Confirm New Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.re_new_password}
                />
                {errors.new_password &&
                  touched.new_password &&
                  errors.new_password}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={
                    "w-full bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black"
                  }
                >
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </figure>
  );
}

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);