import axios from 'axios';
import {
    // USER_DETAIL_SUCCESS,
    // USER_DETAIL_FAIL,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    // USER_CREATE_SUCCESS,
    // USER_CREATE_FAIL,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL
} from '../constants/usertypes';


export const load_user_by_id = () => async dispatch => {

}


export const load_user_list = () => async dispatch => {
    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/users/`, config);
    
            dispatch({
                type: USER_LIST_SUCCESS,
                payload: res.data
            });
        } catch(err) {
            dispatch({
                type: USER_LIST_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LIST_FAIL
        });
    }
};

export const user_delete = (id) => async dispatch => {

    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/accounts/users/delete/${id}/`, config);

        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: res.data
        });

    } catch(err) {
        dispatch({
            type: USER_DELETE_FAIL
        });
    }
};
}

export const user_update_profile = (id, email, name, is_staff) => async dispatch => {

    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

    const body = JSON.stringify({id, email, name, is_staff});

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/accounts/users/update/${id}/`, body, config);

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: res.data
        });

    } catch(err) {
        dispatch({
            type: USER_UPDATE_FAIL
        });
    }
};
}