import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Logout from './pages/Logout';
import AdminSignup from './pages/account/admin/AdminSignup';
import Activate from './pages/Activate';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
import AdminDashboard from './pages/account/admin/AdminDashboard';
import AdminListContent from './pages/account/admin/AdminListContent';
import AdminUpdateContent from './pages/account/admin/AdminUpdateContent';
import Dashboard from './pages/account/Dashboard';

import { Provider } from 'react-redux';
import store from './store';

import Layout from './hocs/Layout';
import AdminUpdateUser from './pages/account/admin/AdminUpdateUser';


const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/admin/signup" element={<AdminSignup />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route exact path="/activate/:uid/:token" element={<Activate />} />
                      
            <Route exact path="/dashboard" element={<Dashboard />} />

            <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
            <Route exact path="/admin/dashboard/user/update/:id" element={<AdminUpdateUser />} />
            <Route exact path="/admin/dashboard/content" element={<AdminListContent />} />
            <Route exact path="/admin/dashboard/content/update/:id" element={<AdminUpdateContent />} />
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
