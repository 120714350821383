import axios from 'axios';
import {
    // CONTENT_REQUEST,
    // CONTENT_CREATE_SUCCESS,
    // CONTENT_CREATE_FAIL,
    CONTENT_DETAIL_SUCCESS,
    CONTENT_DETAIL_FAIL,
    CONTENT_LIST_SUCCESS,
    CONTENT_LIST_FAIL,
    CONTENT_UPDATE_SUCCESS,
    CONTENT_UPDATE_FAIL,
    CONTENT_DELETE_SUCCESS,
    CONTENT_DELETE_FAIL
} from '../constants/contenttypes';


export const load_content_by_id = () => async dispatch => {

    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/content/all/`, config);
            const content_types = await axios.get(`${process.env.REACT_APP_API_URL}/content/content-types`, config)

            dispatch({
                type: CONTENT_DETAIL_SUCCESS,
                payload: res.data,
                contentTypes: content_types
            });
        } catch(err) {
            dispatch({
                type: CONTENT_DETAIL_FAIL
            });
        }
    } else {
        dispatch({
            type: CONTENT_LIST_FAIL
        });
    }
};


export const load_content_list = () => async dispatch => {

    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/content/all/`, config);
            const content_types = await axios.get(`${process.env.REACT_APP_API_URL}/content/content-types`, config)

            dispatch({
                type: CONTENT_LIST_SUCCESS,
                payload: res.data,
                contentTypes: content_types
            });
        } catch(err) {
            dispatch({
                type: CONTENT_LIST_FAIL
            });
        }
    } else {
        dispatch({
            type: CONTENT_LIST_FAIL
        });
    }
};


export const content_delete = (id) => async dispatch => {
   
    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/content/delete/${id}/`, config);

        dispatch({
            type: CONTENT_DELETE_SUCCESS,
            payload: res.data
        });

    } catch(err) {
        dispatch({
            type: CONTENT_DELETE_FAIL
        });
    }
};
}

export const content_update = (id, author, type, title, active, content) => async dispatch => {

    if(localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

    const body = JSON.stringify({id, author, type, title, active, content});

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/content/update/${id}/`, body, config);

        dispatch({
            type: CONTENT_UPDATE_SUCCESS,
            payload: res.data
        });

    } catch(err) {
        dispatch({
            type: CONTENT_UPDATE_FAIL
        });
    }
};
}