import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../actions/auth';

const Activate = ({ verify }) => {

  const [verified, setVerified] = useState(false);

  const params = useParams();

  const verify_account = () => {
    setTimeout(() => {
      verify(params.uid, params.token);
      // setRequestSent(true);
      setVerified(true);

    }, 400);
  }
 
  if(verified) {
    return <Navigate to="/login" />
  }

  return (
    <figure className="h-screen flex bg-gray-100">
    <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
      <blockquote className="text-2xl font-medium text-center">
        <p className="text-lg font-semibold">Activate</p>
      </blockquote>

      <div className="text-primary m-6">
        <div className="flex items-center mt-3 justify-center">
          <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
            Verify your account
          </h1>
        </div>


     
           
                <button
                  type="submit"
                  onClick={verify_account}
                  className={
                    "w-full bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black"
                  }
                >
                  Activate Account
                </button>

           
      
    </div>
  </div>
  </figure>
  );
}


export default connect(null, { verify })(Activate);
