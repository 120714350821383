import {
    CONTENT_DETAIL_SUCCESS,
    CONTENT_DETAIL_FAIL,
    CONTENT_CREATE_SUCCESS,
    CONTENT_CREATE_FAIL,
    CONTENT_LIST_SUCCESS,
    CONTENT_LIST_FAIL,
    CONTENT_UPDATE_SUCCESS,
    CONTENT_UPDATE_FAIL,
    CONTENT_DELETE_SUCCESS,
    CONTENT_DELETE_FAIL
} from '../constants/contenttypes';

const initialState = {
    content: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    const { type, payload, contentTypes } = action;

    switch(type) {
        case CONTENT_DETAIL_SUCCESS:
        case CONTENT_LIST_SUCCESS:
            return {
                content: payload,
                types: contentTypes
            }
            case CONTENT_DETAIL_FAIL:
            case CONTENT_LIST_FAIL:
                return {
                    ...state,
                    content: [],
                    loading: false
                }
        case CONTENT_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                content: payload
            }
        case CONTENT_CREATE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case CONTENT_UPDATE_SUCCESS: 
            return {
                ...state,
                content: payload
            }
        case CONTENT_UPDATE_FAIL: 
            return {
                ...state,
            }
        case CONTENT_DELETE_SUCCESS: 
            return {
                ...state,
                success: true
            }
        case CONTENT_DELETE_FAIL: 
            return {
                ...state
            }
        
        default: 
            return state
    }
}