export const CONTENT_REQUEST = 'CONTENT_REQUEST';

export const CONTENT_CREATE_SUCCESS = 'CONTENT_CREATE_SUCCESS';
export const CONTENT_CREATE_FAIL = 'CONTENT_CREATE_FAIL';

export const CONTENT_DETAIL_SUCCESS = 'CONTENT_DETAIL_SUCCESS';
export const CONTENT_DETAIL_FAIL = 'CONTENT_DETAIL_FAIL';

export const CONTENT_LIST_SUCCESS = 'CONTENT_LIST_SUCCESS';
export const CONTENT_LIST_FAIL = 'CONTENT_LIST_FAIL';

export const CONTENT_UPDATE_SUCCESS = 'CONTENT_UPDATE_SUCCESS';
export const CONTENT_UPDATE_FAIL = 'CONTENT_UPDATE_FAIL';

export const CONTENT_DELETE_SUCCESS = 'CONTENT_DELETE_SUCCESS';
export const CONTENT_DELETE_FAIL = 'CONTENT_DELETE_FAIL';