import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";

import axios from 'axios';

const Login = ({ login, isAuthenticated, is_staff, loginError }) => {

  //Is the user authenticated
  //redirect to home page
  if (is_staff && isAuthenticated) {
    return <Navigate to="/admin/dashboard" />;
  } else if (is_staff === false && isAuthenticated) {
    return <Navigate to ="/dashboard" />;
  }

  const LoginSchema = Yup.object().shape({
    checkEmail: Yup.boolean().default(true),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required")
      .when("checkEmail", {
        is: true,
        then: Yup.string()
          .test({
            message: () => "The is no account with that email",
            test: async(email) => {
              if(email) {
                try {
                  let response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/users/check_email/?email=${email}`);
                  if (response.data === true) {
                    return true;
                  } else {
                    return false;
                  }
                } catch (error) {
                  //console.log(error);
                }
              }
            }
          })
      }),   
    password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password is too short")
  });

  return (
    <figure className="h-screen flex bg-gray-100">
      <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
        <blockquote className="text-2xl font-medium text-center">
          <p className="text-lg font-semibold">Welcome to Your App</p>
        </blockquote>

        <div className="text-primary m-6">
          <div className="flex items-center mt-3 justify-center">
            <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
              Login to your account
            </h1>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting, setStatus }) => {
              setStatus();
              setTimeout(() => {
                login(
                  values.email,
                  values.password
                );
                // setRequestSent(true);
                setSubmitting(false);
                
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              status
            }) => (
              <form onSubmit={handleSubmit}>
                {status}
                <label className="text-left">Email:</label>
                <input
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className={
                    "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
                  }
                  required
                />
                 {errors.email && touched.email && (
              <div className="text-red-500">{errors.email}</div>
            )}
                <label>Password:</label>
                <input
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Password"
                  minLength="8"
                  className={
                    "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
                  }
                />
                 {errors.password && touched.password && (
                    <div className="text-red-500">{errors.password}</div>
                  )}
                  {loginError ? <div className="text-red-500">{loginError}</div> : ""}
                  <br/>
                <div className="">
                  <button
                    className={
                      "w-full bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black"
                    }
                    value="Login"
                    type="submit"
                  >
                    Login
                  </button>
                  <div className="w-full items-center mt-3 justify-center">
                    Need to register?&nbsp;
                    <button
                      className={"justify-center text-blue-500 hover:underline"}
                    >
                      <Link to="/signup"> Sign Up</Link>
                    </button>
                  </div>
                  <div className="w-full items-center mt-3 justify-center">
                    Forgot Password? &nbsp;
                    <button
                      className={"justify-center text-blue-500 hover:underline"}
                    >
                      <Link to="/reset-password"> Reset Password</Link>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </figure>
  );
};

const mapStateToProps = (state) => ({
  //is authenticated
  isAuthenticated: state.auth.isAuthenticated,
  is_staff: state.auth.user.is_staff,
  loginError: state.auth.error
});

export default connect(mapStateToProps, { login })(Login);

// export default Login;
