import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { TrashIcon } from '@heroicons/react/24/solid';
import { PencilIcon } from '@heroicons/react/24/solid';
import { CheckIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { load_content_list, content_delete } from "../../../actions/content";

const AdminListContent = () => {

  const dispatch = useDispatch()
  let navigate = useNavigate()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isStaff = useSelector(state => state.auth.user.is_staff)

  if (isAuthenticated === false) {
    navigate('/login')
  }

  if(isStaff === false) {
    navigate('/dashboard')
  }

  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.content)
    // acccess state to for useEffect array to refresh page
  const deleteContent = useSelector(state => state.content.success)

  const contentDelete = (id) => {
    dispatch(content_delete(id))
  }

  useEffect(() => {
    dispatch(load_content_list())
  }, [dispatch, deleteContent])

  
  return (
    <>
      <span>You are staff</span>
      <div>Welcome {user.name}</div>
      <b />
      <table className="table-auto border-separate border-spacing-2 border-slate-400 w-full pr-10 pl-10 pt-5">
        <thead className="bg-slate-200">
          <tr>
          <th className="border border-slate-300">ID</th>
          <th className="border border-slate-300">Author</th>
          <th className="border border-slate-300">Type</th>
          <th className="border border-slate-300">Title</th>
          <th className="border border-slate-300">Active</th>
          <th className="border border-slate-300">Body</th>
          <th className="border border-slate-300">Update</th>
          <th className="border border-slate-300">Delete</th>

          </tr>
        </thead>
        <tbody>
       {data.content.map(i =>
           <tr key={i.id}>
            <td className="border border-slate-300">{i.id}</td>
            <td className="border border-slate-300">{i.author.name}</td>
             <td className="border border-slate-300">{i.type}</td>
             <td className="border border-slate-300">{i.title}</td>
             <td className="border border-slate-300">{i.active ? (<CheckIcon className="h-6 w-6 text-green-500 m-auto" />) 
                                                                   : (<XMarkIcon className="h-6 w-6 text-red-500 m-auto" />)}</td>
            <td className="border border-slate-300">{i.content}</td>
             <td className="border border-slate-300 ">
               <button className="pl-6">
               <Link to={{pathname: `/admin/dashboard/content/update/${i.id}`}}>
                 <PencilIcon className="h-6 w-6 text-slate-300 m-auto"/>
               </Link>
               </button>
              </td>
             <td className="border border-slate-300">
               <button className="pl-6" onClick={() => contentDelete(i.id)}>
               <TrashIcon className="h-6 w-6 text-slate-300 m-auto"/>
               </button>
              </td>
           </tr>
            )} 
        </tbody>
      </table>
      
    </>
  );
}


export default AdminListContent;
