import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { logout } from '../actions/auth';


const Navbar = ({logout, isAuthenticated }) => {

  const user = useSelector(state => state.auth.user)
  const logout_user = () => {
    logout();
  }

  const guestPrimaryLinks = () => (
    <Fragment>
      <ul className="inline">
        <li className="inline px-4">
          <Link to="/">Projects</Link>
        </li>
        <li className="inline px-4">
          <Link to="#">Sponsor</Link>
        </li>
      </ul>
    </Fragment>
  );

  const authPrimaryLinks = () => (
    <Fragment>
      <ul className="inline">
        <li className="inline px-4">
          {user.is_staff ? (
            <Link to="/admin/dashboard">Dashboard</Link>
          ) : (
            <Link to="/dashboard">Dashboard</Link>
          )}
        </li>
        <li className="inline px-4">
          <Link to="/">Link 2</Link>
        </li>
      </ul>
    </Fragment>
  );

  const guestAccountLinks = () => (
    <Fragment>
        <span className="px-5">
          <Link to="login">Join</Link>
        </span>
        <span className="px-4">
          <Link to="signup">Signup</Link>
        </span>
    </Fragment>
  );

  const authAccountLinks = () => (
        <Fragment>
        <span className="px-4">
          <Link to="/logout" onClick={logout_user}>Logout</Link>
        </span>
      </Fragment>
  );

  return (
    <div className="navbar h-screen min-h-full w-full border-b-2 border-slate-400 flex justify-between">
      <div className="brand p-4">
        <Link className="text-2xl text-red-600" to="/">d-pkg</Link>
      </div>
      <div className="p-4">
      {isAuthenticated ? authPrimaryLinks() : guestPrimaryLinks()}
      </div>
      <div className="p-4">
        {isAuthenticated ? authAccountLinks() : guestAccountLinks()}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { logout})(Navbar);
