import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { TrashIcon } from '@heroicons/react/24/solid';
import { PencilIcon } from '@heroicons/react/24/solid';
import { CheckIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { load_user_list, user_delete } from "../../../actions/user";

const AdminDashboard = () => {


  const dispatch = useDispatch()
  let navigate = useNavigate()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isStaff = useSelector(state => state.auth.user.is_staff)

  if (isAuthenticated === false) {
    navigate('/login')
  }

  if(isStaff === false) {
    navigate('/dashboard')
  }

  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.users)
  // acccess state to for useEffect array to refresh page
  const deleteUser = useSelector(state => state.users.success)

  const userDelete = (id) => {
    dispatch(user_delete(id))
  }

  useEffect((id) => {
    dispatch(load_user_list())
  }, [dispatch, deleteUser])

  return (
    <>
      <span>You are staff</span>
      <div>Welcome {user.name}</div>
      <div>
        <Link to="/admin/dashboard/content">List all content</Link>
      </div>
      <b />
      <table className="table-auto border-separate border-spacing-2 border-slate-400 w-full pr-10 pl-10 pt-5">
        <thead className="bg-slate-200">
          <tr>
          <th className="border border-slate-300">ID</th>
          <th className="border border-slate-300">Name</th>
          <th className="border border-slate-300">Email</th>
          <th className="border border-slate-300">Staff</th>
          <th className="border border-slate-300">Update</th>
          <th className="border border-slate-300">Delete</th>
          </tr>
        </thead>
        <tbody>
       {data.users.map(i =>
           <tr key={i.id}>
            <td className="border border-slate-300">{i.id}</td>
             <td className="border border-slate-300">{i.name}</td>
             <td className="border border-slate-300">{i.email}</td>
             <td className="border border-slate-300">{i.is_staff ? (<CheckIcon className="h-6 w-6 text-green-500 m-auto" />) 
                                                                   : (<XMarkIcon className="h-6 w-6 text-red-500 m-auto" />)}</td>
             <td className="border border-slate-300 ">
               <button className="pl-6">
               <Link to={{pathname: `/admin/dashboard/user/update/${i.id}`}}>
                 <PencilIcon className="h-6 w-6 text-slate-300 m-auto"/>
               </Link>
               </button>
              </td>
             <td className="border border-slate-300">
               <button className="pl-6" onClick={() => userDelete(i.id)}>
               <TrashIcon className="h-6 w-6 text-slate-300 m-auto"/>
               </button>
              </td>
           </tr>
            )} 
        </tbody>
      </table>
      
    </>
  );
}


export default AdminDashboard;
