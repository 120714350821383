import React from "react";
import Sidebar from "./Sidebar";

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export default function Map() {

  const mdIcon = L.icon({
    iconUrl: require('../assets/map_icons/mcd.png'),
    iconSize: [64,32],
    iconAnchor: [32, 64],
    popupAnchor: [0, -64],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
});

const bpIcon = L.icon({
  iconUrl: require('../assets/map_icons/bp.png'),
  iconSize: [112,64],
  iconAnchor: [32, 64],
  popupAnchor: [0, -64],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});

  return (
    <>
      <div className="absolute h-full w-full ">
        <MapContainer
          style={{zIndex: 0}}
          center={[51.505, -0.09]}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker icon={mdIcon} position={[51.505, -0.09]}>
            <Popup>
              McDonalds sponsors <br /> this project.
            </Popup>
          </Marker>
          <Marker icon={bpIcon} position={[51.506, -0.011]}>
            <Popup>
              BP sponsors. <br /> this project.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      <div className="absolute ml-[80%] h-full w-1/5 z-200">
        <div className="-screen h-screen grid grid-flow-col place-items-center group z-50">
        <Sidebar />
      </div>
      </div>
    </>
  );
}
