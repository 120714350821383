import React from 'react'

import Map from '../components/Map';

const Home = () => {
  return (
    <>
      <Map />
    </>
  );
}

export default Home;