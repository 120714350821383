import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const Dashboard = () => {

  let navigate = useNavigate()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isStaff = useSelector(state => state.auth.user.is_staff)
  const user = useSelector(state => state.auth.user)

  if (isAuthenticated === false) {
    navigate('/login')
  }

  if(isStaff === true) {
    navigate('/admin/dashboard')
  }

  return (
    <>
      <span>You are not staff</span>
      <div>Welcome {user.name}</div>
      <div>{user.is_staff}</div>
      <div>{user.email}</div>
    </>
  )
}


export default Dashboard;