import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { connect } from 'react-redux';
import { check_authenticated, load_user } from '../actions/auth';

const Layout = ({check_authenticated, load_user, children}) => {

  useEffect(() => {
    check_authenticated();
    load_user();
  }, [check_authenticated, load_user])

  return (
    <div>
        <Navbar />
        {children}
       
    </div>
  );
};


export default connect(null, { check_authenticated, load_user }) (Layout);
