import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Navigate, useParams } from 'react-router-dom';
import { user_update_profile } from '../../../actions/user';


const AdminUpdateUser = (props) => {

    const dispatch = useDispatch()

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const users = useSelector(state => state.users.users)

    const { id } = useParams() 
    let user = {}
    try {
      user = users.filter((person) => Number(id) === Number(person.id))
    } catch (error) {
      //console.log('error:', error)
    }
    user = user[0]
    //console.log(user.is_staff)
    // console.log('is staff: ', user.is_staff)

  if( !isAuthenticated) {
    return <Navigate to='/' />
  }

  const UpdateUserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email"),     
   
  });

  return (
    <figure className="h-screen flex bg-gray-100">
    <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
      <div className="text-primary m-6">
        <div className="flex items-center mt-3 justify-center">
          <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
            
            Update User {user.name}
          </h1>
        </div>
      <Formik
        initialValues={{ id: user.id, name: user.name, email: user.email, is_staff: user.is_staff}}
        validationSchema={UpdateUserSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setStatus();
          setTimeout(() => {
            // console.log(values.is_staff)
            dispatch(user_update_profile(user.id, values.email, values.name, values.is_staff));

            //setRequestSent(true);
            setSubmitting(false);
            setStatus("User updated.")
          }, 1000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          status
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.name && touched.name && (
             <div className="text-red-500">{errors.name}</div>
            )}
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.email && touched.email && (
              <div className="text-red-500">{errors.email}</div>
            )}

            <input 
              type="checkbox"
              name="is_staff"
              value={values.is_staff}
              disabled={false}
              onChange={() => setFieldValue("is_staff", !values.is_staff)}
              checked={values.is_staff}
            />
            {(<div className="text-center text-green-500 text-sm">{status}</div>)}
            <br />
            <button
              type="submit"
              disabled={isSubmitting}
              className={
                "w-full bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black"
              }
            >
              Update User
            </button>

           
          </form>  
        )}
         
      </Formik>
      
    </div>
  </div>
  </figure>
  );
}



export default AdminUpdateUser;
