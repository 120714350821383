import React from 'react'


const Logout = () => {
  return (
    <div>
      You have successfully logged out.
    </div>
  );
}

export default Logout;