import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Navigate, useParams } from 'react-router-dom';
import { content_update } from '../../../actions/content';


const AdminUpdateContent = () => {

     const dispatch = useDispatch()

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const contents = useSelector(state => state.content.content)
    const options = useSelector(state => state.content.types.data)

    const { id } = useParams() 
    let content = {}
    try {
      content = contents.filter((c) => Number(id) === Number(c.id))

    } catch (error) {
      //console.log('error:', error)
    }
    content = content[0]

    if( !isAuthenticated) {
    return <Navigate to='/' />
  }

  const UpdatecontentSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email"),     
   
  });

  return (
    <figure className="h-screen flex bg-gray-100">
    <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
      <div className="text-primary m-6">
        <div className="flex items-center mt-3 justify-center">
          <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
            
            Update content {content.name}
          </h1>
        </div>
      <Formik
         // 'id', 'author', 'type', 'title', 'active', 'content'
        initialValues={{ id: content.id, author: content.author.name, type: content.type, title: content.title, active: content.active, content: content.content}}
        validationSchema={UpdatecontentSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setStatus();
          setTimeout(() => {
            // console.log(values.is_staff)
            dispatch(content_update(content.id, values.author, values.type, values.title, values.active, values.content));

            //setRequestSent(true);
            setSubmitting(false);
            setStatus("content updated.")
          }, 1000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          status
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="author"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.author}
              placeholder="Author"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.name && touched.name && (
             <div className="text-red-500">{errors.name}</div>
            )}
            <Field 
              as="select" 
              name="types"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            >
                <option disabled value="">{content.type}</option>
                {options.map((option, index)=> (
                    <option key={index} value={option[1]}>{option[1]}</option>
                ))}
            </Field>
            <input
              type="title"
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              placeholder="Title"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            />
            {errors.title && touched.title && (
              <div className="text-red-500">{errors.title}</div>
            )}

            <input 
              type="checkbox"
              name="active"
              value={values.active}
              disabled={false}
              onChange={() => setFieldValue("active", !values.active)}
              checked={values.active}
            />
            <label htmlFor="active" className="pl-2">Active</label>
            {(<div className="text-center text-green-500 text-sm">{status}</div>)}
            <Field 
              as="textarea" 
              name="content"
              className={
                "w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
              }
            >
            </Field>
            <br />
            <button
              type="submit"
              disabled={isSubmitting}
              className={
                "w-full bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black"
              }
            >
              Update content
            </button>

           
          </form>  
        )}
         
      </Formik>
      
    </div>
  </div>
  </figure>
  );
}



export default AdminUpdateContent;
